import { graphql } from "gatsby"
import React from "react"
import propTypes from "prop-types"

import Layout from "../../layouts"
import ContentBlock from "../../components/content-block"

import Footer from "../../components/footer"
import Header from "../../components/header"
import SEO from "../../components/seo"
import {
  guideContentBlockStyles,
  guideContainer,
  guidePaddingBottom,
} from "../../utils/styles"
import Hero from "./hero"

const ReactGuidePage = ({ data, location }) => {
  const { contentfulPage: page } = data
  const { pathname, href } = location

  return (
    <Layout pathname={pathname}>
      <SEO contentfulPage={page} url={href} />
      <Header isInverted />
      <Hero page={page} />

      <div css={theme => [guideContainer(theme), guidePaddingBottom(theme)]}>
        <ContentBlock contentBlock={page.contentBlocks[1]} />

        {page.contentBlocks.slice(2).map(cb => (
          <div key={cb.id} css={theme => guideContentBlockStyles(theme)}>
            <ContentBlock contentBlock={cb} />
          </div>
        ))}
      </div>
      <Footer />
    </Layout>
  )
}

ReactGuidePage.propTypes = {
  data: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
}

export default ReactGuidePage

export const pageQuery = graphql`
  query ReactGuidePage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      parentPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
      ...ContentBlocks
      ...SocialMediaImage
    }
  }
`
