import React, { Fragment } from "react"
import propTypes from "prop-types"

import HeroContainer from "../../components/containers/hero"
import PullBelowHeader from "../../components/containers/pull-below-header"
import ContentBlock from "../../components/content-block"
import heroBottom from "../../assets/guides-react-hero-bottom.svg"
import reactLogo from "../../assets/guides-react.svg"
import LinkToParentPage from "../../components/link-to-parent-page"
import Title from "../../components/hero/title"
import { guideContainer } from "../../utils/styles"
import { rhythm } from "../../utils/typography"

const styles = theme => ({
  heroBottomSvg: {
    bottom: -1,
    display: `block`,
    marginBottom: 0,
    position: `absolute`,
    width: `100%`,
  },
  subheader: {
    color: theme.colors.white,
    fontSize: theme.fontSizes[3],
    marginBottom: theme.space[13],
  },
  reactLogo: {
    height: 400,
    left: -180,
    position: `absolute`,
    top: 0,
    transition: `opacity ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
    width: 400,
    "@media (max-width: 1150px)": {
      opacity: 0.3,
    },
    "@media (max-width: 750px)": {
      display: `none`,
    },
  },
})

const Hero = ({ page }) => {
  const subheaderParagraphs = page.subtitle.subtitle
    .split(`\n`)
    .filter(item => item !== `\\n`)
    .map((item, i) => <p key={i}>{item}</p>)

  return (
    <PullBelowHeader>
      <HeroContainer isInverted css={{ background: `#1c3e47` }}>
        <div
          css={{
            position: `relative`,
            marginBottom: rhythm(6),
          }}
        >
          <div
            css={theme => [
              guideContainer(theme),
              {
                WebkitFontSmoothing: `antialiased`,
                paddingBottom: rhythm(6),
                [theme.mediaQueries.phablet]: {
                  paddingBottom: rhythm(8),
                },
                [theme.mediaQueries.desktop]: {
                  paddingBottom: rhythm(10),
                },
              },
            ]}
          >
            {page.parentPage && (
              <LinkToParentPage contentfulPage={page} isInverted />
            )}
            <Title
              isInverted
              css={theme => ({
                marginTop: theme.space[10],
                marginBottom: theme.space[10],
                maxWidth: 500,
              })}
            >
              {page.title}
            </Title>
            <div css={theme => styles(theme).subheader}>
              {subheaderParagraphs.map((p, i) => (
                <Fragment key={i}>{p}</Fragment>
              ))}
              <ContentBlock contentBlock={page.contentBlocks[0]} />
            </div>
          </div>
          <img
            css={theme => styles(theme).reactLogo}
            src={reactLogo}
            alt="React Logo"
          />
          <img
            css={theme => styles(theme).heroBottomSvg}
            src={heroBottom}
            alt="Shapes"
          />
        </div>
      </HeroContainer>
    </PullBelowHeader>
  )
}

Hero.propTypes = {
  page: propTypes.object.isRequired,
}

export default Hero
